<template>
<div class="gridlines-container horizontal-guide" >
    <div class="guides" aria-hidden="true">
        <div class="guides-container">
            <div class="guide"></div>
            <div class="guide"></div>
            <div class="guide"></div>
            <div class="guide"></div>
            <div class="guide"></div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.gridlines-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.horizontal-guide:after{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        margin: 0;
        border: none;
        background: linear-gradient(90deg,rgba(66,71,112,0.09),rgba(66,71,112,0.09) 50%,transparent 0,transparent);
        background-size: 8px 1px;
        content: "";
    }
    .guides{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        padding: 0 16px;
        pointer-events: none;
        .guides-container{
            display: grid;
            grid: 1fr / repeat(4 ,1fr);
            position: relative;
                max-width: 1140px;
            height: 100%;
            margin: 0 auto;
            .guide{
                width: 1px;
                background: linear-gradient(180deg,rgba(66,71,112,0.09),rgba(66,71,112,0.09) 50%,transparent 0,transparent);
                background-size: 1px 8px;
                &:last-of-type, &:first-of-type {
                    background: rgba(66,71,112,0.06);
                }
                &:last-of-type{
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                }
            }

        }
    }
}
</style>
