import "./assets/css/index.css";
import "./assets/css/bootstrap-grid.css";

import { createApp } from "vue";

import VueGtag from "vue-gtag-next";

import { gsap } from "gsap";
import { MotionPlugin } from '@vueuse/motion';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin";

import BaseHeader from "@/components/UI/BaseHeader.vue";
import BaseFooter from "@/components/UI/BaseFooter.vue";

import BaseButton from "@/components/UI/BaseButton.vue";
import BaseList from "@/components/UI/BaseList.vue";

// import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import "bootstrap/dist/css/bootstrap-grid.min.css";

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);

// App.use(VueMeta);
const app = createApp(App);

app.use(router);

app.use(VueGtag, {
  property: {
    id: "UA-2163792-35"
  }
});
app.use(MotionPlugin);
// app.use(VueMeta);
app.component("fa", FontAwesomeIcon);
app.mount("#app");
app.mixin({
  created: function test() {
    this.gsap = gsap;
  },
  methods: {
    getInTouch() {
      this.$router.push("get-in-touch");
    },
  },
});

// Global Component
app.component("base-header", BaseHeader);
app.component("base-footer", BaseFooter);
app.component("base-button", BaseButton);
app.component("base-list", BaseList);
