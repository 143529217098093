// @/plugins/font-awesome.ts
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
    fad, faVideoPlus, faSitemap, faUsers, faBrowser, faPaperPlane,
} from '@fortawesome/pro-duotone-svg-icons';
import FontAwesomeIcon from '@/components/FontAwesomeIcon.vue';

library.add(fas, fad, faVideoPlus, faSitemap, faUsers, faBrowser, faPaperPlane);

export { FontAwesomeIcon };

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// // import {
// //     faUsers as farUsers, faPiggyBank, faDonate, faCoin, faUniversity, faPoundSign, faCheck, faTimes,
// // } from '@fortawesome/pro-regular-svg-icons';
// // import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
// // import {
// //     faBell, faCogs, faCommentDots, faTimes,
// // } from '@fortawesome/pro-solid-svg-icons';
// // import {
// //     faBullseye, faDesktop, faMailBulk, faAnalytics, faChess, faQuestion, faInfo, faSun, faMoon, faSignOutAlt,
// // } from '@fortawesome/pro-light-svg-icons';
// import {
//     faVideo,
// } from '@fortawesome/pro-duotone-svg-icons';

// import { library } from '@fortawesome/fontawesome-svg-core';
