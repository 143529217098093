import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { setSeoData } from "@/utils/setMetaData";
import { trackRouter } from "vue-gtag-next";
import store from '../store';
import Home from "../pages/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      seo: [
        { title: "Enact" },
        { meta: [{ name: "description", content: "Real time integration and analytics for GB and European energy markets" }] },
      ],
    },
  },
  {
    path: "/explore-enact",
    name: "ExploreEnact",
    component: () =>
      import(/* webpackChunkName: "explore" */ "../pages/ExploreEnact.vue"),
    meta: {
      seo: [
        { title: "Enact Features" },
        { meta: [{ name: "description", content: "Combine machine learning with energy market expertise to produce live forecasts of wind, demand, NIV and system prices." }] },
      ],
    },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: () =>
      import(/* webpackChunkName: "Subscriptions" */ "../pages/Subscriptions.vue"),
    meta: {
      seo: [
        { title: "Enact Subscription Plans" },
        { meta: [{ name: "description", content: "Enact offer four types of subscriptions to help you" }] },
      ],
    },
  },
  {
    path: "/testimonials",
    name: "Testimonial",
    component: () =>
      import(/* webpackChunkName: "reviews" */ "../pages/Reviews.vue"),
    meta: {
      seo: [
        { title: "Enact Testimonials" },
        { meta: [{ name: "description", content: "Reviews of Enact" }] },
      ],
    },
  },
  {
    path: "/get-in-touch",
    name: "Get In Touch",
    component: () =>
      import(/* webpackChunkName: "GetInTouch" */ "../pages/GetInTouch.vue"),
    meta: {
      seo: [
        { title: "Enact Trial" },
        { meta: [{ name: "description", content: "LCP Energy team will be happy to arrange a demo for you." }] },
      ],
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "PrivacyPolicy" */ "../pages/PrivacyPolicy.vue"),
    meta: {
      seo: [
        { title: "Privacy policy" },
        { meta: [{ name: "description", content: "This is a description" }] },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

trackRouter(router);

router.beforeEach((to, _from, next) => {
  // call function to set the title and description
  setSeoData((to.meta.seo as any));
  store.closeMenu();
  next();
});

export default router;
