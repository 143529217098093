const testimonials = [
  {
    companyName: "InterGen",
    content: `<p>InterGen have been using Enact since 2019 and are impressed that <span class="highlightTest">LCP continually enhance and augment their offering</span>.</p>
    <p>Enact provides InterGen with a <span class="highlightTest">mass of information and analysis in an easy to use, intuitive format</span> that provides great decision support for our in-house traders.</p>
		<p>The live EPEX feed has allowed for a degree of remote supervision and input from managers that would not have been otherwise possible for us during lockdown.</p>
		<p>Their <span class="highlightTest">customer service is excellent</span> and InterGen have been impressed with their turnaround time for a number of display enhancements we have worked on in partnership.</p>`,
    highlight: `<p>"Enact provides InterGen with a <span class="highlightTest">mass of information and analysis in an easy to use, intuitive format</span> that provides great decision support for our in-house traders."</p>`,
    clientName: `Lisa Mackay`,
    role: `Commercial Director`,
    logo: `intergen.png`,
    colour: "#0073ae",
  },
  {
    companyName: "EDF Renewables",
    content: `<p>Enact makes it <span class="highlightTest">extremely easy to understand events in, locate and extract data on short-term electricity markets</span>.</p>
    <p>In particular, Enact helps us keep on top of the various fast-evolving routes to earning revenue for technologies that provide flexibility.</p>`,
    highlight: `<p>"Enact makes it <span class="highlightTest">extremely easy to understand events in, locate and extract data on short-term electricity markets</span>.</p>
    <p>In particular, Enact helps us keep on top of the various fast-evolving routes to earning revenue for technologies that provide flexibility."</p>`,
    clientName: `Bharath Kantharaj`,
    role: `Portfolio Optimisation Manager`,
    logo: `edfr.png`,
    colour: "#f45412",
  },
  // {
  //   companyName: "GridBeyond",
  //   content: `<p>We chose LCP Enact because we found it to be the <span class="highlightTest">best product to support trading short term power markets</span>.</p>
  //   <p>Enact offers a combination of <span class="highlightTest">detailed real-time market insight, intuitive visualisations, cutting edge forecasts and a responsive team</span>.</p>
  //   <p>This ultimately allows us to trade more effectively and offer the best service to our clients.</p>`,
  //   highlight: `<p>We chose LCP Enact because we found it to be the <span class="highlightTest">best product to support trading short term power markets</span>.</p>
  //   <p>"Enact offers a combination of <span class="highlightTest">detailed real-time market insight, intuitive visualisations, cutting edge forecasts and a responsive team</span>."</p>`,
  //   clientName: ``,
  //   role: `Managing Director`,
  //   logo: `gridbeyond.svg`,
  //   colour: "#6157e9",
  // },
  {
    companyName: "Open Energi",
    content: `<p>We were instantly impressed with the <span class="highlightTest">intuitive click through design</span> of the platform.</p><p>This gives a clear overall picture of the system but easily enables one to drill down into the detail too.
    </p><p>LCP are <span class="highlightTest">experts in data visualisation as well as the market trends</span> so the platform is ideal for our needs.</p>`,
    highlight: `<p>"We were instantly impressed with the <span class="highlightTest">intuitive click through design</span> of the platform.</p><p>LCP are <span class="highlightTest">experts in data visualisation as well as the market trends</span> so the platform is ideal for our needs."</p>`,
    clientName: `Sebastian Blake`,
    role: `Head of Markets & Policy`,
    logo: `openenergi.png`,
    colour: "#275d63",
  },
  {
    companyName: "Falck Renewables",
    content: `<p>Enact is an <span class="highlightTest">extraordinary tool for those who optimise their assets in the BM</span> and monitor the standard and non-standard events on an intraday basis.
    </p><p>The platform is very user friendly and allows us to <span class="highlightTest">access a huge amount of data with ease</span>; this is extremely important when you need to make decisions quickly.
    </p><p>Furthermore the <span class="highlightTest">LCP team is very prepared, helpful and enthusiastic</span> about both the energy market and data analysis.
    </p>`,
    highlight: `<p>"Enact is an <span class="highlightTest">extraordinary tool for those who optimise their assets in the BM</span> and monitor the standard and non-standard events on an intraday basis."</p>`,
    clientName: `Sebastian Blake`,
    role: `Power Trader`,
    logo: `falck.png`,
    colour: "#009e9e",
  },
  {
    companyName: "Habitat",
    content: `<p>We’re delighted to be working with LCP as <span class="highlightTest">they share our desire to turn data into the best set of commercial decisions for energy assets</span>.</p>
    	<p>We’ve been impressed by the <span class="highlightTest">ease of LCP’s onboarding process</span> and are enthusiastic about working together.</p>`,
    highlight: `<p>"LCP share our desire to <span class="highlightTest">turn data into the best set of commercial decisions</span> for energy assets.</p>`,
    clientName: `Phil Robinson`,
    role: `Co-Founder`,
    logo: `habitat.png`,
    colour: "#00a41b",
  },
  {
    companyName: "Centrica",
    content: `<p>The advanced features and resilience of Enact has allowed it to become smoothly integrated within our workflow.</p>
		<p>The speed and flexibility of the platform offers <span class="highlightTest">unparalleled insight into the market</span> and our competitors, ultimately allowing us to deliver the trading strategies that provide <span class="highlightTest">the best results for our clients</span>.</p>`,
    highlight: `<p>"The speed and flexibility of the platform offers <span class="highlightTest">unparalleled insight into the market</span> and our competitors, ultimately allowing us to deliver the trading strategies that provide <span class="highlightTest">the best results for our clients</span>."</p>`,
    clientName: `Taimoor Zaman`,
    role: `Head of Energy Trading and Balancing Markets`,
    logo: `centrica.png`,
    colour: "#3c468e",
  },
  {
    companyName: "CCI",
    content: `<p>We required a platform to help us make decisive investment decisions across the power space, with a particular interest in <span class="highlightTest"> assessment of trading strategies and benchmarking of battery storage assets</span>. After assessing multiple platforms on the market, Enact was the clear choice. It was the only platform where we could perform both <span class="highlightTest">macro, high level analysis</span> as well as <span class="highlightTest">micro, detailed investigations in a quick, easy and extremely user-friendly manner</span>.</p>`,
    highlight: `<p>We required a platform to help us make decisive investment decisions across the power space, with a particular interest in assessment of trading strategies and benchmarking of battery storage assets. After assessing multiple platforms on the market, Enact was the clear choice. It was the only platform where we could perform both macro, high level analysis as well as micro, detailed investigations in a quick, easy and extremely user-friendly manner</p>`,
    clientName: ``,
    role: `Test`,
    logo: `cci.svg`,
    colour: "#0347cb",
  },
  {
    companyName: "BKW",
    content: `<p>Enact is <span class="highlightTest">a market leading and incredibly user friendly platform</span>.</p>
		<p><span class="highlightTest">The ability to quickly find exactly what we need</span>, from understanding sudden market events in real time to predicting upcoming prices, helps us to be more informed.</p>`,
    highlight: `<p>"Enact is <span class="highlightTest">a market leading and incredibly user friendly platform</span>.</p>
    <p><span class="highlightTest">The ability to quickly find exactly what we need</span>, from understanding sudden market events in real time to predicting upcoming prices, helps us to be more informed."</p>`,
    clientName: `Gurbrinder Randhawa`,
    role: `Lead Intraday Trader`,
    logo: `bkw.svg`,
    colour: "#eb6608",
  },
  {
    companyName: "Confidential",
    content: `<p>Enact’s flexibility, intuitive views and forecasting capabilities meant switching from our previous market intelligence provider was <span class="highlightTest">vital in maintaining our competitive edge</span>.</p>
		<p>The team made the transition seamless and continue to deliver <span class="highlightTest">features that keep us ahead</span>.</p>`,
    highlight: `<p>"Enact’s <span class="highlightTest">flexibility, intuitive views and forecasting capabilities</span> meant switching from our previous market intelligence provider was vital in maintaining our competitive edge."</p>`,
    clientName: `Lead Intraday Trader`,
    role: ``,
  },
  {
    companyName: "Dare",
    content: `<p>Enact has a <span class="highlightTest">great front-end, is intuitive, and provides all the data we need</span> to cover the UK short-term power market.</p>
    <p>On top of this, the development team at LCP have been <span class="highlightTest">extremely responsive</span> to all our requests and couldn’t be more helpful.</p>`,
    highlight: `<p>"Enact has a <span class="highlightTest">great front-end, is intuitive, and provides all the data we need</span> to cover the UK short-term power market."</p>`,
    clientName: ``,
    role: ``,
    logo: `dare.svg`,
    colour: "#0347cb",
  },
];

export default testimonials;
