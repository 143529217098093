import { reactive } from 'vue';

const store = {
    debug: true,
    state: reactive({
        menuActive: false
    }),
    toggleMenu(): void {
       this.state.menuActive = !this.state.menuActive;
},
closeMenu(): void {
    this.state.menuActive = false;
}
};

export default store;
