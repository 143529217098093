<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="$props.class"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path fill="currentColor" :opacity="svgPath2 ? 0.4 : 1" :d="svgPath1" />
    <path fill="currentColor" :d="svgPath2" v-if="svgPath2" />
  </svg>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

export default defineComponent({
    name: 'FontAwesomeIcon',

    props: {
        icon: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'fas',
            required: false,
        },
        class: String,
    },

    setup(props) {
        const definition = computed(() => findIconDefinition({
            prefix: props.type,
            iconName: props.icon,
        }));

        const width = computed(() => definition.value.icon[0]);
        const height = computed(() => definition.value.icon[1]);
        const svgPath1 = computed(() => (Array.isArray(definition.value.icon[4]) ? definition.value.icon[4][0] : definition.value.icon[4]));
        const svgPath2 = computed(() => (Array.isArray(definition.value.icon[4]) ? definition.value.icon[4][1] : null));

        return {
            width, height, svgPath1, svgPath2,
        };
    },
});
</script>
