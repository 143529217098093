export const setSeoData = (data: Array<any>) => {
  const titleTag = document.getElementsByTagName("title")[0];
  const head = document.getElementsByTagName("head")[0];
  const meta = document.createElement("meta");
  if (data) {
    data.forEach((d) => {
      if (d.meta) {
        d.meta.forEach((m): void => {
          meta.name = m.name;
          meta.content = m.content;
        });
      }
    });
    titleTag.innerText = data[0].title;
  }
  head.appendChild(meta);
};
